<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-secondary display-5 mb-2 mb-md-3">
            {{ $t("menu.service_requests") }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <!-- <table class="table table-striped table-responsive"> -->
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <!-- <th scope="col">Data</th> -->
                <th scope="col" v-if="$loggedUser().is_famiglia">Affiliato</th>
                <th scope="col" v-if="$loggedUser().is_affiliato">Cliente</th>
                <!-- <th scope="col">Servizio</th> -->
                <!-- <th scope="col">Citt&agrave;</th> -->
                <th scope="col">Indirizzo</th>
                <!-- <th scope="col">Operatore</th> -->
                <!-- <th scope="col">Familiare</th> -->
                <!-- <th scope="col">Durata</th> -->
                <!-- <th scope="col" v-if="this.$loggedUser().is_affiliato">
                  Badante
                </th> -->
                <th scope="col">Prezzo</th>
                <th scope="col">Assistito</th>
                <th scope="col">Stato</th>
                <th scope="col">Azioni</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(request, index) in serviceRequests" :key="index">
                <td>
                  {{ index + 1 }}
                </td>
                <!-- <td>
                  Dal {{ new Date(request.dateInizio).toLocaleDateString() }} al {{ new Date(request.dateFine).toLocaleDateString() }}
                </td> -->
                <td>
                  <span v-if="request.affiliate_user && $loggedUser().is_famiglia">{{
                    request.affiliate_user.ragione_sociale
                  }}</span>
                  <span v-if="request.family_user && $loggedUser().is_affiliato">{{
                    request.family_user.first_name + ' ' + request.family_user.last_name 
                  }}</span>
                </td>
                <!-- <td>
                  <span v-if="request.service && request.service.categoria">{{
                    request.service.categoria.name
                  }}</span>
                </td> -->
                <!-- <td>
                  <span v-if="request.city">{{
                    request.city.comune + " (" + request.city.province + ")"
                  }}</span>
                </td> -->
                <td>
                  <span v-if="request.address">{{
                    request.address
                  }}</span>
                </td>
                <!-- <td>
                  <span v-if="request.family_user">
                    {{
                      request.family_user.first_name +
                      " " +
                      request.family_user.last_name
                    }}
                  </span>
                </td> -->
                 <!-- <td>
                  <span v-if="request.caretaker_user">
                    {{ formatCaretakerName(request.caretaker_user) }}
                  </span>
                </td> -->
                <!-- <td> 
                  <span v-if="request.durata">
                    {{ request.durata.toFixed(2) + " ore" }}
                  </span>
                </td> -->
                <!-- <td>
                  <span v-if="request.caretaker_user">{{
                    request.caretaker_user.first_name +
                    " " +
                    request.caretaker_user.last_name
                  }}</span>
                </td> -->
                <td>
                  {{ request.prezzo + " €" }}
                </td>
                <td>
                  {{ formatAssistedName(request.assisted_name) }}
                </td>
                <td>
                  {{ statusLabel(request.process_status) }}
                </td>
                <td>
                  <router-link
                    class="mr-2"
                    :to="{
                      name: 'service_request_detail',
                      params: { id: request.id, canEdit: false },
                    }"
                  >
                    Vedi
                  </router-link>
                  <router-link
                    class="mr-2"
                    v-if="canEdit(request)"
                    :to="{
                      name: 'service_request_detail',
                      params: { id: request.id, canEdit: true },
                    }"
                  >
                    Modifica
                  </router-link>
                  <router-link
                    class="mr-2"
                    v-if="isPayable(request)"
                    :to="{
                      name: 'payment',
                      params: { id: request.id },
                    }"
                  >
                    Paga
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import i18n from "@/i18n";
import { mapActions, mapGetters } from "vuex";
import { processStatuses } from "@/services/endpoints";
import { request } from "http";

export default {
  name: "ServiceRequestsList",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.service_requests"),
    meta: [{ name: "description", content: i18n.t("menu.service_requests") }],
  },
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loading: "serviceRequests/loading",
      serviceRequests: "serviceRequests/serviceRequests",
    }),
  },
  methods: {
    ...mapActions("serviceRequests", ["getServiceRequests"]),
    statusLabel(status) {
      switch (parseInt(status)) {
        case processStatuses.notProcessed:
          return "Non elaborato";
        case processStatuses.confirmed:
          return "Confermato";
        case processStatuses.rejected:
          return "Annullato";
        case processStatuses.paid:
          return "Pagato";
        case processStatuses.completed:
          return "Completato";
        default:
          return "N/A";
      }
    },
    isPayable(request) {
      return (
        this.$loggedUser().is_famiglia &&
        request.process_status !== undefined &&
        parseInt(request.process_status) === processStatuses.confirmed
      );
    },
    // eslint-disable-next-line no-unused-vars
    canEdit(_request) {
      // @todo: Other rules?
      return this.$loggedUser().is_staff || this.$loggedUser().is_affiliato;
    },
    formatCaretakerName(caretaker_user) {
      return caretaker_user.id == 1 ? 'Non assegnato' : caretaker_user.first_name + ' ' + caretaker_user.last_name
    },
    formatAssistedName(assisted_name) {
      return assisted_name == 'nessuno' ? 'Non assegnato' : assisted_name
    }
  },
  mounted() {
    // this.getServiceRequests(!this.$loggedUser().is_staff);
    this.getServiceRequests(true);
  },
};
</script>
