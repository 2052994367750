var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row my-4" }, [
    !_vm.imageRight
      ? _c("div", { staticClass: "col-md-12 col-lg-6" }, [
          _c("div", { staticClass: "img_container" }, [
            _c("img", {
              attrs: { alt: _vm.service.name, src: _vm.service.image }
            })
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-6" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "request_service",
                params: { service: _vm.service }
              }
            }
          },
          [
            _c("h2", { staticClass: "display-5 text-uppercase mb-2" }, [
              _vm._v(" " + _vm._s(_vm.service.name) + " ")
            ])
          ]
        ),
        _vm._v(" " + _vm._s(_vm.service.description) + " ")
      ],
      1
    ),
    _vm.imageRight
      ? _c("div", { staticClass: "col-md-12 col-lg-6" }, [
          _c("div", { staticClass: "img_container" }, [
            _c("img", {
              attrs: { alt: _vm.service.name, src: _vm.service.image }
            })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }