var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("h1", { staticClass: "text-secondary display-5 mb-2 mb-md-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("menu.service_requests")) + " ")
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
                _vm.$loggedUser().is_famiglia
                  ? _c("th", { attrs: { scope: "col" } }, [_vm._v("Affiliato")])
                  : _vm._e(),
                _vm.$loggedUser().is_affiliato
                  ? _c("th", { attrs: { scope: "col" } }, [_vm._v("Cliente")])
                  : _vm._e(),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Indirizzo")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Prezzo")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Assistito")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Stato")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Azioni")])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.serviceRequests, function(request, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(" " + _vm._s(index + 1) + " ")]),
                  _c("td", [
                    request.affiliate_user && _vm.$loggedUser().is_famiglia
                      ? _c("span", [
                          _vm._v(_vm._s(request.affiliate_user.ragione_sociale))
                        ])
                      : _vm._e(),
                    request.family_user && _vm.$loggedUser().is_affiliato
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              request.family_user.first_name +
                                " " +
                                request.family_user.last_name
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("td", [
                    request.address
                      ? _c("span", [_vm._v(_vm._s(request.address))])
                      : _vm._e()
                  ]),
                  _c("td", [_vm._v(" " + _vm._s(request.prezzo + " €") + " ")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatAssistedName(request.assisted_name)) +
                        " "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.statusLabel(request.process_status)) +
                        " "
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            to: {
                              name: "service_request_detail",
                              params: { id: request.id, canEdit: false }
                            }
                          }
                        },
                        [_vm._v(" Vedi ")]
                      ),
                      _vm.canEdit(request)
                        ? _c(
                            "router-link",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                to: {
                                  name: "service_request_detail",
                                  params: { id: request.id, canEdit: true }
                                }
                              }
                            },
                            [_vm._v(" Modifica ")]
                          )
                        : _vm._e(),
                      _vm.isPayable(request)
                        ? _c(
                            "router-link",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                to: {
                                  name: "payment",
                                  params: { id: request.id }
                                }
                              }
                            },
                            [_vm._v(" Paga ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }