<template>
  <div class="row my-4">
    <!-- Image Left -->
    <div v-if="!imageRight" class="col-md-12 col-lg-6">
      <div class="img_container">
        <img
          :alt="service.name"
          :src="service.image"
        />
      </div>
    </div>
    <!-- Service description -->
    <div class="col-md-12 col-lg-6">
      <router-link
        :to="{
          name: 'request_service',
          params: { service: service },
        }"
        ><h2 class="display-5 text-uppercase mb-2">
          {{ service.name }}
        </h2>
      </router-link>
      {{ service.description }}
    </div>
    <!-- Image Right -->
    <div v-if="imageRight" class="col-md-12 col-lg-6">
      <div class="img_container">
        <img
          :alt="service.name"
          :src="service.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fields } from "@/services/endpoints";

export default {
  name: "ServiceCard",
  props: {
    service: {
      type: Object,
      required: true,
    },
    imageRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: fields,
    };
  },
};
</script>

<style scoped>
.img_container {
  position: relative;
}

/* Make the image responsive */
.img_container img {
  width: 100%;
  height: auto;
}
</style>
