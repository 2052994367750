var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.services.items
        ? _c("ServicesCarousel", { attrs: { services: _vm.old_services } })
        : _vm._e(),
      _c("div", { staticClass: "container mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("p", [
              _vm._v(
                " MyCare opera con partners selezionati sul territorio nazionale per offrire soluzioni alle famiglie che hanno bisogno di persone di fiducia e qualificate per svolgere assistenza agli anziani, ai bambini o per le pulizie di casa. "
              )
            ]),
            _c("h4", [_vm._v("Richiedi un nostro Servizio")]),
            _c("p", [
              _vm._v(
                "Chiedere un servizio di assistenza MyCare è semplicissimo:"
              )
            ]),
            _vm._m(0),
            _c("p", [
              _vm._v(
                " Dalla tua area riservata potrai controllare i servizi attivi, gestirli, chiuderli e dare una valutazione sugli stessi. "
              )
            ]),
            _c("h4", [_vm._v("Le nostre Garanzie")]),
            _vm._m(1),
            _c("h4", [_vm._v("Perché MyCare")]),
            _vm._m(2),
            _c(
              "div",
              { staticClass: "mt-4 p-4 bg-secondary text-white" },
              [
                _vm.services.items
                  ? _c("RequestServiceSearchForm", {
                      attrs: { services: _vm.services.items }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c(
                "div",
                { staticClass: "p-4 bg-secondary text-white" },
                [
                  _vm.services.items
                    ? _c("RequestServiceSearchForm", {
                        attrs: { services: _vm.services.items }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
                _vm.services.items
                  ? _c(
                      "div",
                      _vm._l(_vm.old_services, function(service, key) {
                        return _c(
                          "div",
                          { key: key },
                          [
                            _c("ServiceCard", {
                              key: key,
                              attrs: {
                                service: service,
                                "image-right": key % 2 === 0
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "mt-2 text-center" },
                      [
                        _c("b-spinner", {
                          attrs: { variant: "primary", label: "Spinning" }
                        })
                      ],
                      1
                    )
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [_vm._v("Compila il form di richiesta")]),
      _c("li", [_vm._v("Scegli l’ufficio più vicino a casa tua")]),
      _c("li", [
        _vm._v(" Registrati al sito o inserisci le tue credenziali di accesso ")
      ]),
      _c("li", [
        _vm._v("Completa il form con tutte le informazioni necessarie")
      ]),
      _c("li", [_vm._v("Invia la richiesta")]),
      _c("li", [
        _vm._v(
          " Attendi la conferma del servizio e il link per eseguire il pagamento "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [
        _vm._v(
          " Scelta del personale in base alle esigenze e con verifica di qualifiche ed esperienze "
        )
      ]),
      _c("li", [
        _vm._v(
          " Possibilità di cancellare il servizio fino a 48h prima dell’erogazione "
        )
      ]),
      _c("li", [
        _vm._v(
          " Pagamento del servizio solo dopo la conferma della disponibilità del personale "
        )
      ]),
      _c("li", [
        _vm._v("Gestione del servizio tramite l’area riservata del sito")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("MyCare")]),
      _vm._v(
        " opera con partner specializzati nel settore con oltre 25 anni di esperienza. E’ ampiamente presente sul territorio nazionale, per cui l’attività di assistenza è erogata da un ufficio territoriale che può monitorare il servizio, conoscere il lavoratore e intervenire di persona per qualsiasi problema e/o necessità. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }