<template>
  <div>
    <ServicesCarousel v-if="services.items" :services="old_services" />
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-6">
          <p>
            MyCare opera con partners selezionati sul territorio nazionale per
            offrire soluzioni alle famiglie che hanno bisogno di persone di
            fiducia e qualificate per svolgere assistenza agli anziani, ai
            bambini o per le pulizie di casa.
          </p>
          <h4>Richiedi un nostro Servizio</h4>
          <p>Chiedere un servizio di assistenza MyCare è semplicissimo:</p>
          <ol>
            <li>Compila il form di richiesta</li>
            <li>Scegli l’ufficio più vicino a casa tua</li>
            <li>
              Registrati al sito o inserisci le tue credenziali di accesso
            </li>
            <li>Completa il form con tutte le informazioni necessarie</li>
            <li>Invia la richiesta</li>
            <li>
              Attendi la conferma del servizio e il link per eseguire il
              pagamento
            </li>
          </ol>
          <p>
            Dalla tua area riservata potrai controllare i servizi attivi,
            gestirli, chiuderli e dare una valutazione sugli stessi.
          </p>
          <h4>Le nostre Garanzie</h4>
          <ol>
            <li>
              Scelta del personale in base alle esigenze e con verifica di
              qualifiche ed esperienze
            </li>
            <li>
              Possibilità di cancellare il servizio fino a 48h prima
              dell’erogazione
            </li>
            <li>
              Pagamento del servizio solo dopo la conferma della disponibilità
              del personale
            </li>
            <li>Gestione del servizio tramite l’area riservata del sito</li>
          </ol>
          <h4>Perché MyCare</h4>
          <p>
            <strong>MyCare</strong> opera con partner specializzati nel settore
            con oltre 25 anni di esperienza. E’ ampiamente presente sul
            territorio nazionale, per cui l’attività di assistenza è erogata da
            un ufficio territoriale che può monitorare il servizio, conoscere il
            lavoratore e intervenire di persona per qualsiasi problema e/o
            necessità.
          </p>
          <div class="mt-4 p-4 bg-secondary text-white">
            <RequestServiceSearchForm
              v-if="services.items"
              :services="services.items"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-4 bg-secondary text-white">
            <RequestServiceSearchForm
              v-if="services.items"
              :services="services.items"
            />
          </div>
          <b-overlay :show="loading" rounded="sm">
            <div v-if="services.items">
              <div :key="key" v-for="(service, key) in old_services">
                <ServiceCard
                  :key="key"
                  :service="service"
                  :image-right="key % 2 === 0"
                />
              </div>
            </div>
            <div v-else class="mt-2 text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServicesCarousel from "@/components/shared/ServicesCarousel";
import i18n from "@/i18n";
import ServiceCard from "@/components/ServiceCard";
import { mapActions } from "vuex";
import RequestServiceSearchForm from "@/components/forms/services/RequestServiceSearchForm";
import { test } from "@/services/service.service"
import RequestServiceFormAffiliate from "../components/forms/services/RequestServiceFormAffiliate.vue";

export default {
  name: "Services",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.services"),
    meta: [{ name: "description", content: i18n.t("menu.services") }],
  },
  components: {
    RequestServiceSearchForm,
    ServicesCarousel,
    ServiceCard,
    RequestServiceFormAffiliate
  },
  data() {
    return {
      old_services: [
        {name: "Babysitter", description: "Le nostre babysitter accudiscono e controllano i vostri figli. Trascorrono alcune ore o l’intera giornata con loro, si assicurano che mangino, studino e facciano tutti i compiti loro assegnati dai genitori.", image: require("@/assets/images/services/babysitter.png")},
        {name: "Colf", description: "La colf si occupa delle faccende domestiche, di pulire e mantenere ordinata la casa. Lavora con ordine e precisione in modo da fornire un servizio di qualità e seguendo le direttive che le vengono impartite.", image: require("@/assets/images/services/colf.png")},
        {name: "Assistenza domiciliare", description: "L’Assistente Domiciliare svolge un’attività di assistenza e compagnia a domicilio per qualche ora, per l’intera giornata e/o per la notte, prendendosi cura della persona e aiutandola nelle sue attività quotidiane, nell’alzarsi, lavarsi, cucinare, etc.", image: require("@/assets/images/services/assistenza_domiciliare.png")},
        {name: "Notti in ospedale", description: "Il nostro personale trascorre la notte in ospedale accudendo il paziente, prestandogli le dovute attenzioni e gestendo qualsiasi situazione che necessiti un loro intervento diretto o il coinvolgimento del personale ospedaliero.", image: require("@/assets/images/services/notti_in_ospedale.png")},
        {name: "Presenza diurna a domicilio", description: "Il servizio di presenza diurna, consente di avere un assistente domiciliare dalle 08.00 alle 20.00 garantendo aiuto durante le attività di alzata e igiene.", image: require("@/assets/images/services/presenza.png")},
        {name: "Presenza notturna a domicilio", description: "Il servizio di presenza notturna garantisce la presenza dalle 20.00 alle 08.00 partendo dalla essa a letto e fornendo aiuto durante la notte.", image: require("@/assets/images/services/presenza.png")},
      ]
    };
  },
  computed: {
    // loading() {
    //   console.log("CIRICIAO")
    //   return this.$store.getters["services/loading"];
    // },
    services() {
      console.log("CIRICIAO")
      console.log(this.$store.state)
      return this.$store.state.services.all;
    },
  },
  methods: {
    ...mapActions("services", ["test"]),
    prevPage() {
      if (this.currentIndex > 0) {
        this.currentIndex -= this.perPage;
      }
    },
    nextPage() {
      if (this.currentIndex + this.perPage < this.cards.length) {
        this.currentIndex += this.perPage;
      }
    },
    loadServices(userId) {
          // Call the test method instead of getAll
          test(userId).then(
            (services) => {
              // Process the fetched services
              this.$store.commit("services/getAllSuccess", services);
            },
            (error) => {
              // Handle error if necessary
              console.error("Error fetching services:", error);
          }
      );
    },
  },
  mounted() {
    const loggedUser = this.$loggedUser();
    //const loggedUserId = this.$loggedUserId().id;
    if (!loggedUser) {
      // Load from API
      console.log("Loading services from API");
      this.loadServices(2);
    } else {
      this.loadServices(loggedUser.id);
    }
  },
};
</script>

<style scoped>
h1,
h2 {
  margin: 0;
}

h3 {
  margin-top: 0.5rem;
}
</style>
